<template>
  <div>
    <textarea :id="instanceId"
              class="form-group form-control"
              ref="redactorx"
              :name="instanceId"
              :placeholder="placeholderText"
              :value="redactorText"
    />
    <b-progress v-show="progress>0"
                :value="progress"
                class="w-100 d-block">
    </b-progress>
  </div>
</template>

<script>
require("@/vendor/redactorx/redactorx.min.css");
require("@/assets/css/redactor.css");

import RedactorX from '../../vendor/redactorx/redactorx'
import '../../vendor/redactorx/plugins/imageresize/imageresize.min'
import '../../vendor/redactorx/plugins/imageposition/imageposition.min'
import '../../vendor/redactorx/plugins/filelink/filelink'
import '../../vendor/redactorx/plugins/beyondgrammar/beyondgrammar.min'
import '../../vendor/redactorx/plugins/clips/clips'
import '../../vendor/redactorx/plugins/emojis/emojis'
import EnvService from "../../env.settings";

const {getEmojisList, getEmojisMap} = require('../../vendor/redactorx/plugins/emojis/data');


export default {
  name: "redactor",
  redactor: false,
  props: {
    text: String,
    placeholder: {
      type: String,
      default: null,
    },
    commentApiUrl: {
      type: String,
      default: null,
    },
    publicProofId: {
      type: String,
      default: null,
    },
    curUsersTaggable: {
      type: Array,
      default: null,
    },
    showPreview: {
      type: Boolean,
      default: false,
    },
    showSource: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    text: function (newValue) {
      this.redactorText = this.setTextBody(newValue);
    }
  },
  data() {
    return {
      redactorText: "",
      placeholderText: "",
      uploadUri: (this.$props.commentApiUrl !== undefined && this.$props.commentApiUrl) ? EnvService.instance.apiHost + this.$props.commentApiUrl : "",
      instanceId: this.$A.GetUUID(),
      loomSdkButton: null,
      progress: 0
    };
  },
  mounted: function () {
    this.initRedactor();
    this.redactorText = this.$props.text === undefined ? "" : this.$props.text;
    this.setTextBody(this.redactorText);
    this.placeholderText = this.$props.placeholder === null ?
        this.$A.LangService.getLabel('review_screen_labels', 'redactor_your_text_placeholder') :
        this.$props.placeholder;
  },
  beforeDestroy() {
    RedactorX(this.$refs.redactorx, "destroy")
    this.redactorx = null
    this.$parent.redactorx = null
  },
  methods: {
    getTextBody: function () {
      let textBody = this.redactorx.app.editor.getContent();
      textBody = textBody.replace("<a", "<a target='_blank'");
      // console.log("redactor text body:", textBody);
      return textBody;
    },
    setTextBody: function (newText) {
      const text = newText || "";
      this.redactorText = text;
      let self = this;
      setTimeout(() => {
        self.setFocusEnd();
        self.redactorx && self.redactorx.app.editor.setContent({html: text});
      }, 250);
    },
    initRedactor: function () {
      let self = this;

      this.registerLoom(this);
      let plugins = ['imageresize', 'imageposition', 'filelink', 'beyondgrammar', 'loom', 'clips', 'emojis']; // , 'filemanager'

      if (this.$props.curUsersTaggable && this.$props.curUsersTaggable.length) {
        plugins.push('handle');
        this.registerHandle(this);
      }

      if (this.$props.showPreview) {
        plugins.push('preview');
        this.registerPreview(this);
      }

      let app = RedactorX(this.$refs.redactorx, {
        plugins: plugins,
        embed: false,
        // handle: {},
        source: self.$props.showSource,
        fileUpload: self.uploadUri,
        context: true,
        editor: {
          focus: true,
          minHeight: "200px",
          pasteLinkTarget: "_blank",
          pasteImages: true,
        },
        image: {
          upload: self.uploadUri,
        },
        filelink: {
          upload: self.uploadUri,
          icon: '<i class="re-icon-file"></i>'
        },
        subscribe: {
          'app.start': function () {

            let mc = document.getElementsByClassName("modal-content");
            for (let i = 0; i < mc.length; mc++) {
              mc[0].removeAttribute("tabindex")
            }
            let mc2 = document.getElementsByClassName("modal-header")
            for (let i = 0; i < mc2.length; mc2++) {
              mc2[0].removeAttribute("tabindex")
            }

          },
          'editor.change': function (event) {
            let html = event.get('html');
            self.redactorText = html;
            self.$emit('input', html)
            return html
          },
          'editor.before.paste': async function (event) {
            if (event.params.e.clipboardData.files.length > 0) {
              let file = event.params.e.clipboardData.files[0];
              let progress = function (percent) {
                self.progress = percent;
                if (self.progress === 100) {
                  setTimeout(() => self.progress = 0, 1000);
                } // reset when completed
              }
              let insertDataUrl = function () {
                let reader = new FileReader();
                reader.onload = function (event) {
                  // console.log(event.target.result); // data url!
                  app.editor.insertContent({html: "<img src='" + event.target.result + "' />"});
                };
                reader.readAsDataURL(file);
              }

              let callbackUploaded = function (uploaded, resultData) {

                if (!uploaded) {
                  insertDataUrl();
                } else {

                  app.editor.insertContent({html: "<img src='" + resultData.data["file-1"].url + "' />"});
                }
              };
              if (self.$props.commentApiUrl === null) {
                await window.$A.ProofFileService.UploadMessageFileReturnUrl(
                    file,
                    self.$props.publicProofId,
                    callbackUploaded,
                    progress)
              } else {
                await window.$A.AnnotationService.UploadCommentFileReturnUrl(
                    file,
                    self.uploadUri,
                    callbackUploaded,
                    progress
                );
              }
            } else {
              // get pasted html
              let html = event.get('html');
              // return html back
              event.set('html', html);
            }
          }
        },
        clips: {
          icon: '<i class="re-icon-clips"></i>',
          items: getEmojisMap()
        },
        emojis: {
          icon: '<i class="re-icon-clips"></i>',
          items: getEmojisMap()
        }
      });
      this.redactorx = app;
      this.$parent.redactorx = app;

    },
    registerLoom: function (redactorComponent) {
      let self;
      RedactorX.add("plugin", "loom", {
        init: function () {
          if (redactorComponent.$A.LoomService.isLoomAvailable) {
            this.loomSdkButton = redactorComponent.$A.LoomService.configureSdkButton();
            this.loomSdkButton.on('recording-start', this._startRecording);
            this.loomSdkButton.on('cancel', this._endRecording);
            this.loomSdkButton.on('complete', this._endRecording);
            this.loomSdkButton.on('insert-click', this._createCommentWithLoom);
          }
          self = this;
        },

        start: function () {
          if (redactorComponent.$A.LoomService.supported) {
            this.app.toolbar.add('loom', {
              icon: '<i class="re-icon-loom"></i>',
              title: "Record with Loom",
              command: "loom.toggle",
              position: {after: 'add'}
            });
          }
        },

        toggle: function () {
          // return this._simulateLoomInLocalHost();
          this._recordWithLoom();
        },

        // _simulateLoomInLocalHost: function () {
        //   this._startRecording();
        //   let t = this;
        //   setTimeout(() => {
        //     t._createCommentWithLoom({sharedUrl: "https://www.google.com"});
        //   }, 1000);
        // },

        _recordWithLoom: function () {
          if (redactorComponent.$A.LoomService.supported && redactorComponent.$A.LoomService.isLoomAvailable) {
            self.loomSdkButton.openPreRecordPanel();
          }
        },

        _createCommentWithLoom: function (recording) {
          self._insertUrl(recording.sharedUrl);
          self._endRecording();
        },

        _startRecording: function () {
          let style = document.createElement("style");
          style.textContent = `
        .em-lns-1k46icp {
          bottom: 100px !important;
        }`;

          document.getElementById("loom-sdk-record-overlay-shadow-root-id").shadowRoot.appendChild(style);
          redactorComponent.$emit("onrecording", true);
        },

        _endRecording: function () {
          redactorComponent.$emit("onrecording", false);
        },

        _insertUrl: function (url) {
          let text = this.app.editor.getContent();
          if (text !== "") {
            this.app.editor.insertContent({html: "<br/>"});
          }
          this.app.editor.insertContent({html: `<br><a target='_blank' href='${url}'>${url}</a><br>`});
        },
      });
    },
    registerHandle: function (redactorComponent) {
      RedactorX.add('plugin', 'handle', {
        translations: {
          en: {
            "handle": {
              "handle": "Handle"
            }
          }
        },
        defaults: {
          url: false,
          start: 1,
          trigger: '@'
        },
        subscribe: {
          'editor.keydown': function (event) {
            this._listen(event);
          },
          'editor.keyup': function (event) {
            this._handle(event);
          }
        },
        init: function () {
          this.redactorComponent = redactorComponent;
        },
        start: function () {
          this.handleLen = this.opts.handle.start;
          this.app.toolbar.add("add_person", {
            title: "Add person",
            text: "@",
            icon: '<i class="re-icon-add-person"></i>',
            command: "handle.open",
            position: {after: 'loom'}
          });
        },
        open: function () {
          this.app.editor.insertContent({html: "<p>@</p>"});
          this._emit()
        },
        stop: function () {
          this._hide();
        },

        // private
        _handle: function (event) {
          var e = event.get('e');
          var key = e.which;
          var ctrl = e.ctrlKey || e.metaKey;
          var arrows = [37, 38, 39, 40];
          var ks = this.app.keycodes;

          if (key === ks.ESC) {
            this.app.selection.restore();
            return;
          }
          if (key === ks.DELETE || key === ks.SPACE || key === ks.SHIFT || ctrl || (arrows.indexOf(key) !== -1)) {
            return;
          }

          if (key === ks.BACKSPACE) {
            this.handleLen = this.handleLen - 2;
            if (this.handleLen <= this.opts.handle.start) {
              this._hide();
            }
          }

          this._emit();
        },
        _listen: function (event) {
          var e = event.get('e');
          var key = e.which;
          var ks = this.app.keycodes;

          // listen enter
          if (this._isShown() && key === ks.ENTER) {
            var $item = this._getActiveItem();
            if ($item.length === 0) {
              this._hideForce();
              return;
            } else {
              e.preventDefault();
              event.stop();
              this._replace(e, $item);
              return;
            }
          }

          // listen down / up
          if (this._isShown() && (key === 40 || key === 38)) {
            e.preventDefault();
            event.stop();

            var $item = this._getActiveItem();
            if ($item.length === 0) {
              var $first = this._getFirstItem();
              this._setActive($first);
            }
            // down
            else if (key === 40) {
              this._setNextActive($item);
            }
            // up
            else if (key === 38) {
              this._setPrevActive($item);
            }
          }
        },
        _getItems: function () {
          return this.$panel.find('.' + this.prefix + '-panel-item');
        },
        _getActiveItem: function () {
          return this._getItems().filter(function ($node) {
            return $node.hasClass('active');
          });
        },
        _getFirstItem: function () {
          return this._getItems().first();
        },
        _getLastItem: function () {
          return this._getItems().last();
        },
        _setActive: function ($el) {
          this._getItems().removeClass('active');
          $el.addClass('active');

          var itemHeight = $el.outerHeight();
          var itemTop = $el.position().top;
          var itemsScrollTop = this.$panel.scrollTop();
          var scrollTop = itemTop + itemHeight * 2;
          var itemsHeight = this.$panel.outerHeight();

          this.$panel.scrollTop(
              scrollTop > itemsScrollTop + itemsHeight ? scrollTop - itemsHeight :
                  itemTop - itemHeight < itemsScrollTop ? itemTop - itemHeight :
                      itemsScrollTop
          );
        },
        _setNextActive: function ($el) {
          var $next = $el.next();
          if ($next.length !== 0) {
            this._setActive($next);
          } else {
            var $first = this._getFirstItem();
            this._setActive($first);
          }
        },
        _setPrevActive: function ($el) {
          var $prev = $el.prev();
          if ($prev.length !== 0) {
            this._setActive($prev);
          } else {
            var $last = this._getLastItem();
            this._setActive($last);
          }
        },
        _emit: function () {
          var re = new RegExp('^' + this.opts.handle.trigger);
          this.handleStr = this.app.selection.getText('before', this.handleLen);

          // detect
          if (re.test(this.handleStr)) {
            this.handleStr = this.handleStr.replace(this.opts.handle.trigger, '');
            this.handleLen++;

            if ((this.handleLen - 1) > this.opts.handle.start) {
              this._load();
            }
          }
        },
        _isShown: function () {
          return (this.$panel && this.$panel.hasClass('open'));
        },
        _load: function () {
          let upper = function camelize(str) {
            return str.replace(/\b\w/g, (l) => l.toUpperCase());
          };
          if (
              this.redactorComponent.$props.curUsersTaggable !== undefined &&
              this.redactorComponent.$props.curUsersTaggable
          ) {
            // console.log(self.$props.curUsersTaggable);
            let o = {};

            // console.log(this.handleStr)
            this.redactorComponent.$props.curUsersTaggable
                .filter(
                    (a) =>
                        a.name.toLowerCase().indexOf(this.handleStr.toLowerCase()) >=
                        0
                )
                .forEach((a) => {
                  a.email = a.email?.replace("@", "&#64;");
                  o[a.type + "_" + a.id] = {
                    item:
                        "<b class='ashore_comment_mention' data-key='" +
                        a.type +
                        "_" +
                        a.id +
                        "'>" +
                        upper(a.name) +
                        " (" +
                        a.email +
                        ")</b>",
                    replacement:
                        "<b class='ashore_comment_mention' data-type='" +
                        a.type +
                        "' data-id='" +
                        a.id +
                        "' style='background-color:" +
                        "'>&#64;" +
                        upper(a.name) +
                        "</b>",
                  };
                });
            this._parse(o);
          }
        },
        _parse: function (json) {
          if (json === '' || (Array.isArray(json) && json.length === 0)) {
            if (this.$panel) this.$panel.remove();
            return;
          }
          var data = (typeof json === 'object') ? json : JSON.parse(json);

          this._build(data);
        },
        _build: function (data) {
          this.data = data;
          this.$panel = this.app.$body.find('.' + this.prefix + '-panel');

          if (this.$panel.length === 0) {
            this.$panel = this.dom('<div>').addClass(this.prefix + '-panel');
            this.app.$body.append(this.$panel);
          } else {
            this.$panel.html('');
          }

          // events
          this._stopEvents();
          this._startEvents();

          // data
          for (var key in data) {
            var $item = this.dom('<div>').addClass(this.prefix + '-panel-item');
            var $trigger = this.dom('<a>').attr('href', '#');
            $trigger.html(data[key].item);
            $trigger.attr('data-key', key);
            $trigger.on('click', this._replace.bind(this));

            $item.append($trigger);
            this.$panel.append($item);
          }

          // position
          var scrollTop = this.app.$doc.scrollTop();
          var pos = this.app.selection.getPosition();

          this.$panel.addClass('open');
          this.$panel.css({
            top: (pos.bottom + scrollTop) + 'px',
            left: pos.left + 'px'
          });

          this.app.selection.save();
        },
        _replace: function (e, $el) {
          e.preventDefault();
          e.stopPropagation();

          var $item;
          if ($el) {
            $item = $el.find('a');
          } else {
            $item = this.dom(e.target);
          }
          var key = $item.attr('data-key');
          var replacement = this.data[key].replacement;

          this.app.marker.insert('start');
          var marker = this.app.marker.find('start');
          if (marker === false) return;

          var $marker = this.dom(marker);
          var current = marker.previousSibling;

          var currentText = current.textContent;
          var re = new RegExp(this.opts.handle.trigger + this.handleStr + '$');

          currentText = currentText.replace(re, '');
          current.textContent = currentText;

          $marker.before(replacement);
          this.app.selection.restoreMarker();

          this._hide();
        },
        _reset: function () {
          this.handleStr = false;
          this.handleLen = this.opts.handle.start;
          this.$panel = false;
        },
        _hide: function (e) {
          var hidable = false;
          var key = (e && e.which);
          var ks = this.app.keycodes;

          if (!e) {
            hidable = true;
          } else if (e.type === 'click' || key === ks.ESC || key === ks.SPACE) {
            hidable = true;
          }

          if (hidable) {
            this._hideForce();
          }
        },
        _hideForce: function () {
          if (this.$panel) this.$panel.remove();
          this._reset();
          this._stopEvents();
        },
        _startEvents: function () {
          var name = 'click.' + this.prefix + '-plugin-handle keydown.' + this.prefix + '-plugin-handle';

          this.app.$doc.on(name, this._hide.bind(this));
          this.app.editor.getEditor().on(name, this._hide.bind(this));
        },
        _stopEvents: function () {
          var name = '.' + this.prefix + '-plugin-handle';

          this.app.$doc.off(name);
          this.app.editor.getEditor().off(name);
        }
      });
    },
    registerPreview: function (redactorComponent) {
      RedactorX.add("plugin", "preview", {
        init: function (app) {
        },
        start: function () {
          this.app.toolbar.add('preview', {
            icon: '<i class="re-icon-preview"></i>',
            title: "Preview",
            command: "preview.toggle",
          });
        },
        toggle: async function () {
          redactorComponent.$emit("preview");
        }
      });
    },
    setFocusEnd: function () {
      if (this.redactorx && this.redactorx.app) {
        this.redactorx.app.editor.setFocus('end');
      }
    }
  }
};
</script>

<style>
.rx-control, .rx-panel {
  z-index: 99999999 !important;
}

.rx-tooltip {
  z-index: 200000 !important;
}

.rx-popup {
  z-index: 200001 !important;
}

.rx-editor, .rx-editor [data-rx-type], .rx-editor a, .rx-editor figcaption, .rx-editor p, .rx-editor td, .rx-editor th {
  font-weight: 400 !important;
}

.rx-voice-label {
  display: none !important;
}

.progress {
  height: 3px !important;
  background-color: #F4F4F6;
  border-top: 0px !important;
  padding-top: 0px !important;
}

.rx-topbar {
  display: none !important;
}

.rx-button-icon {
  display: inherit !important;
}

.rx-container{
  border-radius: 6px;
}
</style>
