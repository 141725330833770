RedactorX.add('plugin', 'clips', {
    translations: {
        en: {
            "clips": {
                "clips": "Clips"
            }
        }
    },
    defaults: {
        icon: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m12.1666667 1c1.0193924 0 1.8333333.83777495 1.8333333 1.85714286v10.28571424c0 1.0193679-.8139409 1.8571429-1.8333333 1.8571429h-8.33333337c-1.01868744 0-1.83333333-.8379215-1.83333333-1.8571429v-10.28571424c0-1.01922137.81464589-1.85714286 1.83333333-1.85714286zm-.1666667 2h-8v10h8zm-2 7c.5522847 0 1 .4477153 1 1 0 .5128358-.3860402.9355072-.8833789.9932723l-.1166211.0067277h-4c-.55228475 0-1-.4477153-1-1 0-.5128358.38604019-.9355072.88337887-.9932723l.11662113-.0067277zm0-3c.5522847 0 1 .44771525 1 1 0 .51283584-.3860402.93550716-.8833789.99327227l-.1166211.00672773h-4c-.55228475 0-1-.44771525-1-1 0-.51283584.38604019-.93550716.88337887-.99327227l.11662113-.00672773zm0-3c.5522847 0 1 .44771525 1 1 0 .51283584-.3860402.93550716-.8833789.99327227l-.1166211.00672773h-4c-.55228475 0-1-.44771525-1-1 0-.51283584.38604019-.93550716.88337887-.99327227l.11662113-.00672773z"/></svg>',
        items: false,
        title: 'Emojis'
    },
    start: function() {
        if (!this.opts.clips.items) return;

        this.app.toolbar.add('clips', {
            title: '## emojis.emojis ##',
            icon: this.opts.clips.icon,
            command: 'clips.popup'
        });
    },
    popup: function(params, button) {
        const popup = this.app.popup.create('clips', {
            title : this.opts.clips.title,
            width : '400px'
        });

        // popup body
        const $body = popup.getBody();

        // items
        const $div = this.dom('<div style="padding: 0 20px 20px 20px;">');

        const $search = this.dom('<input type="text" placeholder="Search" style="width: 100%; margin-bottom: 10px;">');
        $search.on('input', this._filter.bind(this));

        const $emojisDiv = this.dom('<div style="height: 100px; max-width: max-content; display: flex; flex-direction: row; flex-wrap: wrap; overflow-y:scroll;">');
        const $preview = this.dom('<div style="border-top: 1px solid #999999; padding-top:10px;  margin-top:10px;">')

        $div.append($search);
        $div.append($emojisDiv);
        $div.append($preview);

        this.searchBox = $search;
        this.emojisDiv = $emojisDiv;
        this.preview = $preview;
        this._load(this.opts.clips.items);

        $body.append($div);
        this.app.popup.open({ button: button });

        $search.focus();
    },
    _setPreview: function(e) {
        this.preview.html("");
        const emoji = this.dom(e.target).attr('data-emoji') || "";
        const title = this.dom(e.target).attr('data-title') || "";
        if (emoji && title) {
            const $item = this.dom(`<a href="#" style="height: 25px; width: 25px; padding-right: 10px; margin-right: 1px;">${emoji}</a>`);
            const $title = this.dom(`<span>:${title}</span>`)
            this.preview.append($item);
            this.preview.append($title);
        }
    },
    _clearPreview: function(e) {
        this._setPreview({});
    },
    _load: function(items) {
        this.emojisDiv.html("");
        for (const item of items) {
            const $item = this.dom(`<a href="#" data-emoji="${item.emoji}" data-title="${item.title}" style="font-size: 25px; height: 25px; width: 25px; padding: 2px; margin: 2px;"></a>`);
            $item.html(item.emoji);
            $item.one('click', this._insert.bind(this));
            $item.on('mouseover', this._setPreview.bind(this));
            $item.on('mouseout', this._clearPreview.bind(this));
            this.emojisDiv.append($item);
        }
    },
    _insert: function(e) {
        e.preventDefault();
        e.stopPropagation();

        this.app.popup.close();

        const $target = this.dom(e.target);
        const value = $target.html();
        this.app.editor.insertContent({ html: value });
    },
    _filter: function(e) {
        e.preventDefault();
        e.stopPropagation();

        const value = e.target.value;
        const data = (value) ?
            this.opts.clips.items.filter(item => item.title.indexOf(value.toLowerCase()) >= 0) :
            this.opts.clips.items;

        this._load(data);
    }
});