RedactorX.add('plugin', 'emojis', {
    translations: {
        en: {
            "emojis": {
                "emojis": "Emojis"
            }
        }
    },
    defaults: {
        start: 1,
        trigger: ':'
    },
    subscribe: {
        'editor.keydown': function (event) {
            this._listen(event);
        },
        'editor.keyup': function (event) {
            this._handle(event);
        }
    },
    start: function () {
        if (!this.opts.emojis.items) return;
        this.handleLen = this.opts.emojis.start;
        this.prefix = this.prefix || 'emojis';
    },
    open: function () {
        this.app.editor.insertContent({html: "<p>:</p>"});
        this._emit()
    },
    stop: function () {
        this._hide();
    },

    // private
    _handle: function (event) {
        const e = event.get('e');
        const key = e.which;
        const ctrl = e.ctrlKey || e.metaKey;
        const arrows = [37, 38, 39, 40];
        const ks = this.app.keycodes;

        if (key === ks.ESC) {
            this.app.selection.restore();
            return;
        }
        if (key === ks.DELETE || key === ks.SPACE || key === ks.SHIFT || ctrl || (arrows.indexOf(key) !== -1)) {
            return;
        }

        if (key === ks.BACKSPACE) {
            this.handleLen = this.handleLen - 2;
            if (this.handleLen <= this.opts.emojis.start) {
                this._hide();
            }
        }

        this._emit();
    },
    _listen: function (event) {
        const e = event.get('e');
        const key = e.which;
        const ks = this.app.keycodes;

        // listen enter
        if (this._isShown() && key === ks.ENTER) {
            const $item = this._getActiveItem();
            if ($item.length === 0) {
                this._hideForce();
                return;
            } else {
                e.preventDefault();
                event.stop();
                this._replace(e, $item);
                return;
            }
        }

        // listen down / up
        if (this._isShown() && (key === 40 || key === 38)) {
            e.preventDefault();
            event.stop();

            const $item = this._getActiveItem();
            if ($item.length === 0) {
                const $first = this._getFirstItem();
                this._setActive($first);
            }
            // down
            else if (key === 40) {
                this._setNextActive($item);
            }
            // up
            else if (key === 38) {
                this._setPrevActive($item);
            }
        }
    },
    _getItems: function () {
        return this.$panel.find('.' + this.prefix + '-panel-item');
    },
    _getActiveItem: function () {
        return this._getItems().filter(function ($node) {
            return $node.hasClass('active');
        });
    },
    _getFirstItem: function () {
        return this._getItems().first();
    },
    _getLastItem: function () {
        return this._getItems().last();
    },
    _setActive: function ($el) {
        this._getItems().removeClass('active');
        $el.addClass('active');

        const itemHeight = $el.outerHeight();
        const itemTop = $el.position().top;
        const itemsScrollTop = this.$panel.scrollTop();
        const scrollTop = itemTop + itemHeight * 2;
        const itemsHeight = this.$panel.outerHeight();

        this.$panel.scrollTop(
            scrollTop > itemsScrollTop + itemsHeight ? scrollTop - itemsHeight :
                itemTop - itemHeight < itemsScrollTop ? itemTop - itemHeight :
                    itemsScrollTop
        );
    },
    _setNextActive: function ($el) {
        var $next = $el.next();
        if ($next.length !== 0) {
            this._setActive($next);
        } else {
            var $first = this._getFirstItem();
            this._setActive($first);
        }
    },
    _setPrevActive: function ($el) {
        var $prev = $el.prev();
        if ($prev.length !== 0) {
            this._setActive($prev);
        } else {
            var $last = this._getLastItem();
            this._setActive($last);
        }
    },
    _emit: function () {
        const re = new RegExp('^' + this.opts.emojis.trigger);
        this.handleStr = this.app.selection.getText('before', this.handleLen);

        // detect
        if (re.test(this.handleStr)) {
            this.handleStr = this.handleStr.replace(this.opts.emojis.trigger, '');
            this.handleLen++;

            if ((this.handleLen - 1) > this.opts.emojis.start) {
                this._load();
            }
        }
    },
    _load: function () {
        const items = this.opts.emojis.items.filter(item => item.title.indexOf(this.handleStr.toLowerCase()) >= 0);
        this._build(items);
    },
    _isShown: function () {
        return (this.$panel && this.$panel.hasClass('open'));
    },
    _build: function (data) {
        this.data = data;
        this.$panel = this.app.$body.find('.' + this.prefix + '-panel');

        if (this.$panel.length === 0) {
            this.$panel = this.dom('<div>').addClass(this.prefix + '-panel');
            this.app.$body.append(this.$panel);
        } else {
            this.$panel.html('');
        }

        // events
        this._stopEvents();
        this._startEvents();

        // data
        for (const item of data) {
            const $item = this.dom('<div>').addClass(this.prefix + '-panel-item');
            const $trigger = this.dom('<a>').attr('href', '#');
            $trigger.html(`${item.emoji} :${item.title}`);
            $trigger.attr('data-emoji', item.emoji);
            $trigger.on('click', this._replace.bind(this));

            $item.append($trigger);
            this.$panel.append($item);
        }

        // position
        const scrollTop = this.app.$doc.scrollTop();
        const pos = this.app.selection.getPosition();

        this.$panel.addClass('open');
        this.$panel.css({
            top: (pos.bottom + scrollTop) + 'px',
            left: pos.left + 'px'
        });

        this.app.selection.save();
    },
    _replace: function (e, $el) {
        e.preventDefault();
        e.stopPropagation();

        let $item;
        if ($el) {
            $item = $el.find('a');
        } else {
            $item = this.dom(e.target);
        }

        const replacement = $item.attr('data-emoji');

        this.app.marker.insert('start');
        const marker = this.app.marker.find('start');
        if (marker === false) return;

        const $marker = this.dom(marker);
        const current = marker.previousSibling;

        let currentText = current.textContent;
        const re = new RegExp(this.opts.emojis.trigger + this.handleStr + '$');

        currentText = currentText.replace(re, '');
        current.textContent = currentText;

        $marker.before(replacement);
        this.app.selection.restoreMarker();

        this._hide();
    },
    _reset: function () {
        this.handleStr = false;
        this.handleLen = this.opts.emojis.start;
        this.$panel = false;
    },
    _hide: function (e) {
        var hidable = false;
        var key = (e && e.which);
        var ks = this.app.keycodes;

        if (!e) {
            hidable = true;
        } else if (e.type === 'click' || key === ks.ESC || key === ks.SPACE) {
            hidable = true;
        }

        if (hidable) {
            this._hideForce();
        }
    },
    _hideForce: function () {
        if (this.$panel) this.$panel.remove();
        this._reset();
        this._stopEvents();
    },
    _startEvents: function () {
        const name = 'click.' + this.prefix + '-plugin-emojis keydown.' + this.prefix + '-plugin-emojis';
        this.app.$doc.on(name, this._hide.bind(this));
        this.app.editor.getEditor().on(name, this._hide.bind(this));
    },
    _stopEvents: function () {
        const name = '.' + this.prefix + '-plugin-emojis';
        this.app.$doc.off(name);
        this.app.editor.getEditor().off(name);
    }
});